// Used to store the _fbp, _fbc and fbclid in session storage
export const state = () => ({
  fbp: undefined, // Not used yet
  fbc: undefined, // Not used yet
  fbclid: undefined,

  // The nuxt-vuex-localstorage version
  version: 6
})

export const mutations = {
  setFbclid: (state, value) => {
    state.fbclid = value
    return state
  }
}

export const getters = {
  getFbclid(state) {
    return state.fbclid
  }
}
