import { NAVIGATION_LINKS_DEFAULT_VALUES } from '~/lib/util/defaultLinks'

// Used to store utility values on a Local Storage like sitewide, sitelinks, bestsellers and recently viewed
export const state = () => ({
  // The Sitewide
  sitewide: {},

  // The Sitewide Mobile version
  sitewidemobile: {},

  // The Site Links Mobile version
  sitelinks: [],

  // The Site Links
  sitelinksmobile: [],

  // The Best Sellers Links
  bestsellers: [],

  // The timestamp used as cache-braker
  timestamp: undefined,

  // The nuxt-vuex-localstorage version
  version: 6
})

export const mutations = {
  sitewide(state, value) {
    state.sitewide = { ...value }
  },

  sitewidemobile(state, value) {
    state.sitewidemobile = { ...value }
  },

  sitelinks(state, values) {
    state.sitelinks = [...values]
  },

  sitelinksmobile(state, values) {
    state.sitelinksmobile = [...values]
  },

  bestsellers: (state, payload) => {
    state.bestsellers = payload
    return state.bestsellers
  },

  timestamp: (state, payload) => {
    state.timestamp = payload
    return state.timestamp
  }
}

export const getters = {
  sitewide: state => {
    return state.sitewide
  },

  sitewidemobile: state => {
    return state.sitewidemobile
  },

  sitelinks: state => {
    return (state?.sitelinks?.length > 0 && state.sitelinks) || NAVIGATION_LINKS_DEFAULT_VALUES
  },

  sitelinksmobile: state => {
    return (
      (state?.sitelinksmobile?.length > 0 && state.sitelinksmobile) || (state?.sitelinks?.length > 0 && state.sitelinks.slice(0, 8)) || NAVIGATION_LINKS_DEFAULT_VALUES.slice(0, 8)
    )
  },

  bestsellers(state) {
    return (state.bestsellers.length > 0 && state.bestsellers) || []
  },

  timestamp(state) {
    return state.timestamp || Date.now()
  }
}
