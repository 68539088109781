import { cacheAdapterEnhancer } from 'axios-extensions'
import LRU from 'lru-cache'
const TEN_MINUTES = 1000 * 60 * 10

export default function({ $axios, ssrContext, redirect }) {
  $axios.defaults.timeout = parseInt(process.env.AWS_API_TIMEOUT)

  const defaultCache = process.server ? ssrContext.$axCache : new LRU({ maxAge: TEN_MINUTES })

  const defaults = $axios.defaults
  // https://github.com/kuitos/axios-extensions
  defaults.adapter = cacheAdapterEnhancer(defaults.adapter, {
    enabledByDefault: false,
    cacheFlag: 'useCache',
    defaultCache
  })

  $axios.onRequest(config => {
    config.metadata = { startTime: new Date() }
    console.log('Making request to ' + config.url)
  })

  $axios.onResponse(response => {
    response.config.metadata.endTime = new Date()
    response.duration = response.config.metadata.endTime - response.config.metadata.startTime
    console.log(`Response duration ${response.duration}ms`)
    return response
  })

  $axios.onError(error => {
    const code = parseInt(error.response && error.response.status)
    error.config.metadata.endTime = new Date()
    error.duration = error.config.metadata.endTime - error.config.metadata.startTime
    console.log(`Error Response duration ${error.duration}ms`)
    if (code === 400) {
      redirect('/404')
    }
  })
}
