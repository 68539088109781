import createRepository from '~/lib/api/repository'
export default (ctx, inject) => {
  ctx.$axios.defaults.timeout = ctx.isDev ? 30000 : parseInt(process.env.AWS_API_TIMEOUT)
  // inject the repository in the context (ctx.app.$repository)
  // And in the Vue instances (this.$repository in your components)
  const repositoryWithAxios = createRepository(ctx.$axios)
  inject(
    'magentoCartAPI',
    repositoryWithAxios(
      'backendapi/cart',
      (ctx.$config && ctx.$config.backendURL) || process.env.BACKEND_URL,
      {
        index: '',
        create: '/create',
        show: '/pull',
        update: '/update',
        delete: '/delete'
      },
      parseInt(process.env.AWS_API_TIMEOUT)
    )
  )

  inject(
    'magentoNewsletterAPI',
    repositoryWithAxios(
      'newsletter/index',
      (ctx.$config && ctx.$config.backendURL) || process.env.BACKEND_URL,
      {
        index: '',
        create: '/create',
        show: '',
        update: '',
        delete: ''
      },
      parseInt(process.env.AWS_API_TIMEOUT)
    )
  )
}
