import Vue from 'vue';

import {
  CardPlugin,
  TabsPlugin,
  DropdownPlugin,
  PaginationPlugin,
  CollapsePlugin,
  ButtonPlugin,
  AlertPlugin,
  ListGroupPlugin,
  BadgePlugin
} from 'bootstrap-vue';

Vue.use(CardPlugin);
Vue.use(TabsPlugin);
Vue.use(DropdownPlugin);
Vue.use(PaginationPlugin);
Vue.use(CollapsePlugin);
Vue.use(ButtonPlugin);
Vue.use(AlertPlugin);
Vue.use(ListGroupPlugin);
Vue.use(BadgePlugin);
