export default {
  // Get the full Basket payload
  payload(state) {
    return state
  },

  // Get the Upsell Basket data
  upsell(state) {
    return state.upsell
  },

  // Get the numOfItems data
  numOfItems(state) {
    return state.numOfItems
  }
}
