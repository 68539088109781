import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _5e3c7d51 = () => interopDefault(import('../pages/christmas-faq.vue' /* webpackChunkName: "pages/christmas-faq" */))
const _e64111b4 = () => interopDefault(import('../pages/contact.vue' /* webpackChunkName: "pages/contact" */))
const _6c6c833e = () => interopDefault(import('../pages/ecard/index.vue' /* webpackChunkName: "pages/ecard/index" */))
const _211523be = () => interopDefault(import('../pages/free-ecard.vue' /* webpackChunkName: "pages/free-ecard" */))
const _02bc23e0 = () => interopDefault(import('../pages/frequently-asked-questions.vue' /* webpackChunkName: "pages/frequently-asked-questions" */))
const _cd2a3444 = () => interopDefault(import('../pages/gift_finder/index.vue' /* webpackChunkName: "pages/gift_finder/index" */))
const _31c3d0e4 = () => interopDefault(import('../pages/gift-card-options.vue' /* webpackChunkName: "pages/gift-card-options" */))
const _c4c89e74 = () => interopDefault(import('../pages/health.vue' /* webpackChunkName: "pages/health" */))
const _4e16ad99 = () => interopDefault(import('../pages/maintenance.vue' /* webpackChunkName: "pages/maintenance" */))
const _02ce7f9c = () => interopDefault(import('../pages/newsletter-signup.vue' /* webpackChunkName: "pages/newsletter-signup" */))
const _33d2f3fe = () => interopDefault(import('../pages/preference-update.vue' /* webpackChunkName: "pages/preference-update" */))
const _6cfe81d6 = () => interopDefault(import('../pages/search/index.vue' /* webpackChunkName: "pages/search/index" */))
const _360a6dc0 = () => interopDefault(import('../pages/generic/category.vue' /* webpackChunkName: "pages/generic/category" */))
const _2fc89ed2 = () => interopDefault(import('../pages/generic/cms.vue' /* webpackChunkName: "pages/generic/cms" */))
const _944ba386 = () => interopDefault(import('../pages/generic/product.vue' /* webpackChunkName: "pages/generic/product" */))
const _8f0cbe38 = () => interopDefault(import('../pages/magazines/e-giftcards/index.vue' /* webpackChunkName: "pages/magazines/e-giftcards/index" */))
const _4e2f91a3 = () => interopDefault(import('../pages/magazines/gift-cards/index.vue' /* webpackChunkName: "pages/magazines/gift-cards/index" */))
const _0dc4864d = () => interopDefault(import('../pages/magazines/pocketmagsplus/index.vue' /* webpackChunkName: "pages/magazines/pocketmagsplus/index" */))
const _0a71f754 = () => interopDefault(import('../pages/magazines/view-all/index.vue' /* webpackChunkName: "pages/magazines/view-all/index" */))
const _6504e4a6 = () => interopDefault(import('../pages/pocketmagsplus/magazines/index.vue' /* webpackChunkName: "pages/pocketmagsplus/magazines/index" */))
const _2dfb1658 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _1f3f56c4 = () => interopDefault(import('../pages/print/ecard/_id.vue' /* webpackChunkName: "pages/print/ecard/_id" */))
const _4d80f21a = () => interopDefault(import('../pages/success/ecard/_id.vue' /* webpackChunkName: "pages/success/ecard/_id" */))
const _88e1ecd2 = () => interopDefault(import('../pages/magazines/e-giftcards/_id/_uuid.vue' /* webpackChunkName: "pages/magazines/e-giftcards/_id/_uuid" */))
const _a15efa54 = () => interopDefault(import('../pages/magazines/gift-cards/_id/_uuid.vue' /* webpackChunkName: "pages/magazines/gift-cards/_id/_uuid" */))
const _06b1db00 = () => interopDefault(import('../pages/magazines/pocketmagsplus/_id/_uuid.vue' /* webpackChunkName: "pages/magazines/pocketmagsplus/_id/_uuid" */))
const _64eec08b = () => interopDefault(import('../pages/ecard/_id/_fromName.vue' /* webpackChunkName: "pages/ecard/_id/_fromName" */))
const _5a5dd441 = () => interopDefault(import('../pages/ecard/_id/_fromName/_toName.vue' /* webpackChunkName: "pages/ecard/_id/_fromName/_toName" */))
const _019ff2d4 = () => interopDefault(import('../pages/gift_finder/_id/_islast.vue' /* webpackChunkName: "pages/gift_finder/_id/_islast" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/christmas-faq",
    component: _5e3c7d51,
    name: "christmas-faq"
  }, {
    path: "/contact",
    component: _e64111b4,
    name: "contact"
  }, {
    path: "/ecard",
    component: _6c6c833e,
    name: "ecard"
  }, {
    path: "/free-ecard",
    component: _211523be,
    name: "free-ecard"
  }, {
    path: "/frequently-asked-questions",
    component: _02bc23e0,
    name: "frequently-asked-questions"
  }, {
    path: "/gift_finder",
    component: _cd2a3444,
    name: "gift_finder"
  }, {
    path: "/gift-card-options",
    component: _31c3d0e4,
    name: "gift-card-options"
  }, {
    path: "/health",
    component: _c4c89e74,
    name: "health"
  }, {
    path: "/maintenance",
    component: _4e16ad99,
    name: "maintenance"
  }, {
    path: "/newsletter-signup",
    component: _02ce7f9c,
    name: "newsletter-signup"
  }, {
    path: "/preference-update",
    component: _33d2f3fe,
    name: "preference-update"
  }, {
    path: "/search",
    component: _6cfe81d6,
    name: "search"
  }, {
    path: "/generic/category",
    component: _360a6dc0,
    name: "generic-category"
  }, {
    path: "/generic/cms",
    component: _2fc89ed2,
    name: "generic-cms"
  }, {
    path: "/generic/product",
    component: _944ba386,
    name: "generic-product"
  }, {
    path: "/magazines/e-giftcards",
    component: _8f0cbe38,
    name: "magazines-e-giftcards"
  }, {
    path: "/magazines/gift-cards",
    component: _4e2f91a3,
    name: "magazines-gift-cards"
  }, {
    path: "/magazines/pocketmagsplus",
    component: _0dc4864d,
    name: "magazines-pocketmagsplus"
  }, {
    path: "/magazines/view-all",
    component: _0a71f754,
    name: "magazines-view-all"
  }, {
    path: "/pocketmagsplus/magazines",
    component: _6504e4a6,
    name: "pocketmagsplus-magazines"
  }, {
    path: "/",
    component: _2dfb1658,
    name: "index"
  }, {
    path: "/print/ecard/:id?",
    component: _1f3f56c4,
    name: "print-ecard-id"
  }, {
    path: "/success/ecard/:id?",
    component: _4d80f21a,
    name: "success-ecard-id"
  }, {
    path: "/magazines/e-giftcards/:id?/:uuid",
    component: _88e1ecd2,
    name: "magazines-e-giftcards-id-uuid"
  }, {
    path: "/magazines/gift-cards/:id?/:uuid",
    component: _a15efa54,
    name: "magazines-gift-cards-id-uuid"
  }, {
    path: "/magazines/pocketmagsplus/:id/:uuid?",
    component: _06b1db00,
    name: "magazines-pocketmagsplus-id-uuid"
  }, {
    path: "/ecard/:id/:fromName?",
    component: _64eec08b,
    name: "ecard-id-fromName",
    children: [{
      path: ":toName?",
      component: _5a5dd441,
      name: "ecard-id-fromName-toName"
    }]
  }, {
    path: "/gift_finder/:id/:islast?",
    component: _019ff2d4,
    name: "gift_finder-id-islast"
  }, {
    path: "/preference-update",
    component: _33d2f3fe,
    name: "custompreference-update"
  }, {
    path: "/magazines/view-all",
    component: _0a71f754,
    name: "view-all"
  }, {
    path: "/magazines/*-magazine",
    component: _944ba386,
    name: "customgeneric-product"
  }, {
    path: "/*-magazine",
    component: _944ba386,
    name: "customgeneric-product1"
  }, {
    path: "/magazines/*-magazine-*",
    component: _944ba386,
    name: "customgeneric-product2"
  }, {
    path: "/magazines/*",
    component: _944ba386,
    name: "customgeneric-product3"
  }, {
    path: "/single-issues/*-magazine-*",
    component: _944ba386,
    name: "customgeneric-product4"
  }, {
    path: "/*/*-magazine-*",
    component: _944ba386,
    name: "customgeneric-product5"
  }, {
    path: "/*/*-newspaper",
    component: _944ba386,
    name: "customgeneric-product6"
  }, {
    path: "/magazines-with-free-gifts/*-magazine",
    component: _944ba386,
    name: "customgeneric-product7"
  }, {
    path: "/:_category/:_subCategory/*-magazine",
    component: _944ba386,
    name: "generic-category-subcategory-product"
  }, {
    path: "/:_category/*-magazine",
    component: _944ba386,
    name: "generic-category-product"
  }, {
    path: "/magazine-*",
    component: _360a6dc0,
    name: "generic-category1"
  }, {
    path: "/*-magazines",
    component: _360a6dc0,
    name: "generic-category2"
  }, {
    path: "/*-subscriptions-*",
    component: _360a6dc0,
    name: "generic-category3"
  }, {
    path: "/*-subscriptions",
    component: _360a6dc0,
    name: "generic-category4"
  }, {
    path: "/magazines-*",
    component: _360a6dc0,
    name: "generic-category5"
  }, {
    path: "/*single-issues*",
    component: _360a6dc0,
    name: "generic-category6"
  }, {
    path: "/discounted-magazine-sale-offers*",
    component: _360a6dc0,
    name: "generic-category7"
  }, {
    path: "/limited-time-offers*",
    component: _360a6dc0,
    name: "generic-category8"
  }, {
    path: "/*-magazines/*",
    component: _360a6dc0,
    name: "generic-category9"
  }, {
    path: "/*-magazine-subscriptions/*",
    component: _360a6dc0,
    name: "generic-category10"
  }, {
    path: "/christmas-gift-ideas",
    component: _360a6dc0,
    name: "generic-category11"
  }, {
    path: "/christmas-gift-ideas/*",
    component: _360a6dc0,
    name: "generic-category12"
  }, {
    path: "/black-friday-promotions",
    component: _360a6dc0,
    name: "generic-category13"
  }, {
    path: "/black-friday-promotions/*",
    component: _360a6dc0,
    name: "generic-category14"
  }, {
    path: "/mothers-day-gift-ideas",
    component: _360a6dc0,
    name: "generic-category15"
  }, {
    path: "/mothers-day-gift-ideas/*",
    component: _360a6dc0,
    name: "generic-category16"
  }, {
    path: "/easter-sale",
    component: _360a6dc0,
    name: "generic-category17"
  }, {
    path: "/easter-sale/*",
    component: _360a6dc0,
    name: "generic-category18"
  }, {
    path: "/radio",
    component: _360a6dc0,
    name: "generic-category19"
  }, {
    path: "/radio/*",
    component: _360a6dc0,
    name: "generic-category20"
  }, {
    path: "/magfest",
    component: _360a6dc0,
    name: "generic-category21"
  }, {
    path: "/magfest/*",
    component: _360a6dc0,
    name: "generic-category22"
  }, {
    path: "/summer-reads",
    component: _360a6dc0,
    name: "generic-category23"
  }, {
    path: "/summer-reads/*",
    component: _360a6dc0,
    name: "generic-category24"
  }, {
    path: "/from-one-pound-per-issue",
    component: _360a6dc0,
    name: "generic-category25"
  }, {
    path: "/from-one-pound-per-issue/*",
    component: _360a6dc0,
    name: "generic-category26"
  }, {
    path: "/back-to-school",
    component: _360a6dc0,
    name: "generic-category27"
  }, {
    path: "/back-to-school/*",
    component: _360a6dc0,
    name: "generic-category28"
  }, {
    path: "/*-percent-off",
    component: _360a6dc0,
    name: "generic-category29"
  }, {
    path: "/*-percent-off/*",
    component: _360a6dc0,
    name: "generic-category30"
  }, {
    path: "/autumn",
    component: _360a6dc0,
    name: "generic-category31"
  }, {
    path: "/autumn/*",
    component: _360a6dc0,
    name: "generic-category32"
  }, {
    path: "/autumn5",
    component: _360a6dc0,
    name: "generic-category33"
  }, {
    path: "/autumn5/*",
    component: _360a6dc0,
    name: "generic-category34"
  }, {
    path: "/early-bird-christmas-gift-subscription-offers",
    component: _360a6dc0,
    name: "generic-category35"
  }, {
    path: "/early-bird-christmas-gift-subscription-offers/*",
    component: _360a6dc0,
    name: "generic-category36"
  }, {
    path: "/christmas-free-gift-promotion",
    component: _360a6dc0,
    name: "generic-category37"
  }, {
    path: "/christmas-free-gift-promotion/*",
    component: _360a6dc0,
    name: "generic-category38"
  }, {
    path: "/international-offers",
    component: _360a6dc0,
    name: "generic-category39"
  }, {
    path: "/international-offers/*",
    component: _360a6dc0,
    name: "generic-category40"
  }, {
    path: "/cyber-monday-promotions",
    component: _360a6dc0,
    name: "generic-category41"
  }, {
    path: "/cyber-monday-promotions/*",
    component: _360a6dc0,
    name: "generic-category42"
  }, {
    path: "/national-geographic-traveller-and-food-offers",
    component: _360a6dc0,
    name: "generic-category43"
  }, {
    path: "/national-geographic-traveller-and-food-offers/*",
    component: _360a6dc0,
    name: "generic-category44"
  }, {
    path: "/valentines-sale",
    component: _360a6dc0,
    name: "generic-category45"
  }, {
    path: "/valentines-sale/*",
    component: _360a6dc0,
    name: "generic-category46"
  }, {
    path: "/summer-of-sport",
    component: _360a6dc0,
    name: "generic-category47"
  }, {
    path: "/summer-of-sport/*",
    component: _360a6dc0,
    name: "generic-category48"
  }, {
    path: "/magazine-subscriptions-for-schools",
    component: _360a6dc0,
    name: "generic-category49"
  }, {
    path: "/magazine-subscriptions-for-schools/*",
    component: _360a6dc0,
    name: "generic-category50"
  }, {
    path: "/new-year-resolutions",
    component: _360a6dc0,
    name: "generic-category51"
  }, {
    path: "/new-year-resolutions/*",
    component: _360a6dc0,
    name: "generic-category52"
  }, {
    path: "/platinum-jubilee",
    component: _360a6dc0,
    name: "generic-category53"
  }, {
    path: "/platinum-jubilee/*",
    component: _360a6dc0,
    name: "generic-category54"
  }, {
    path: "/the-great-british-bake-off-promotions",
    component: _360a6dc0,
    name: "generic-category55"
  }, {
    path: "/treats-under-10",
    component: _360a6dc0,
    name: "generic-category56"
  }, {
    path: "/flash-sale",
    component: _360a6dc0,
    name: "generic-category57"
  }, {
    path: "/terms-and-conditions",
    component: _2fc89ed2,
    name: "cms1"
  }, {
    path: "/*",
    component: _2fc89ed2,
    name: "all"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
