export default function({ app, store, query }) {
  // Save Commission Junction Affiliate if present in the query string
  try {
    if (query.aff && query.aff === 'commissionjunction') {
      // Convert all the query params lowercase (ES10)
      const queryToLowerCase = Object.fromEntries(Object.entries(query).map(([k, v]) => [k.toLowerCase(), v]))

      // Update the affiliate code
      const token = queryToLowerCase.cjevent

      if (token !== undefined) {
        if (process.server) {
          // Server-side
          app.$cookies.set('cje', token, {
            path: '/',
            maxAge: 33696000, // 13 months
            domain: process.env.BACKEND_COOKIES_DOMAIN,
            secure: process.env.NODE_ENV === 'production'
          })
          // CJ Legacy Implementation
          app.$cookies.set('CJ', token, {
            path: '/',
            maxAge: 2592000, // 30 days
            domain: process.env.BACKEND_COOKIES_DOMAIN,
            secure: process.env.NODE_ENV === 'production'
          })
        } else {
          // Client-side
          this.$cookies.set('cje', token, {
            path: '/',
            maxAge: 33696000, // 13 months
            domain: process.env.BACKEND_COOKIES_DOMAIN,
            secure: process.env.NODE_ENV === 'production'
          })
          // CJ Legacy Implementation
          this.$cookies.set('CJ', token, {
            path: '/',
            maxAge: 2592000, // 30 days
            domain: process.env.BACKEND_COOKIES_DOMAIN,
            secure: process.env.NODE_ENV === 'production'
          })
        }
        return store.commit('s/affiliate', token)
      }
    }

    // Update the Commission Junction Affiliate on the local state is the cookie has been already set up
    if (process.server) {
      if (app && app.$cookies.get('cje') !== undefined) {
        store.commit('s/affiliate', app.$cookies.get('cje'))
      }
      // CJ Legacy Implementation
      if (app && app.$cookies.get('CJ') !== undefined) {
        store.commit('s/affiliate', app.$cookies.get('CJ'))
      }
    } else {
      if (this && this.$cookies.get('cje') !== undefined) {
        store.commit('s/affiliate', this.$cookies.get('cje'))
      }
      // CJ Legacy Implementation
      if (this && this.$cookies.get('CJ') !== undefined) {
        store.commit('s/affiliate', this.$cookies.get('CJ'))
      }
    }
  } catch (err) {
    console.log('affiliateDetection ERROR')
  }
}
