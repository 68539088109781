export function getStatusCodeFromPath({ context }) {
  if (context.$route.path.includes('/404')) {
    return 404
  }

  if (context.$route.path.includes('/500')) {
    return 500
  }
  return 200
}

export async function callAWSFrontendAPI({ context, path, query, useCache, type }) {
  const config = context?.$nuxt?.$config || context?.$config || null
  // Calling the Frontend Api for the Page (we have axios-cache LRU in place)
  process.env.NODE_ENV !== 'production' && require('axios-debug-log')
  return await context.$axios
    .get(config.AWSAPIGatewayURL + '/page', {
      headers: {
        'Content-Type': 'application/json',
        'x-api-key': config.AWSAPIGatewayXAPIKEY,
        'Cache-Control': useCache === true ? 'max-age=3600' : 'max-age=0'
      },
      params: {
        path
      },
      timeout: config.AWSAPIGatewayTimeout,
      useCache
    })
    .then(response => {
      // console.log('FRONTEND RESPONSE=', response)
      if (response?.status === 200 && response?.data?.payload?.Items) {
        // The response payload could contain any page types: URLREDIRECT, PRODUCTPAGE, CATEGORYPAGE or CMSPAGE
        const items = response.data.payload.Items

        // Redirect to a specific page if a URLREDIRCT#path has been defined
        const urlRedirectData = items.find(i => i.type === 'URLREDIRECT' && i.status === 'ENABLED')
        console.log('FRONTEND URLREDIRECT=', urlRedirectData)
        if (urlRedirectData?.urlRedirect) {
          // Redirect on the Homepage if there is no data coming from the API
          context.$nuxt.context.redirect(302, urlRedirectData?.urlRedirect, query)
        }

        // Fill the UI data
        const pageData = items.find(i => i.type === type && i.status === 'ENABLED')
        console.log('FRONTEND PAGEDATA=', pageData)
        if (pageData?.payload) {
          return {
            ...pageData.payload,
            expiresAt: pageData.expiresAt,
            updatedAt: pageData.updatedAt,
            status: pageData.status,
            sk: pageData.sk,
            createdAt: pageData.createdAt,
            pk: pageData.pk,
            type: pageData.type,
            statusCode: 200
          }
        } else {
          // The page has been disabled or it has not been found -> 404
          console.log('NO DATA AVAILABLE -> 404')
          if (process.server) {
            context.$nuxt.context.res.statusCode = 404
          }
          context.$store.commit('UI/statusCode', 404, { root: true })

          return {
            statusCode: 404
          }
        }
      } else {
        console.log('FRONTEND ERROR data=', response.data)

        // IMPORTANT Redirections for 404 and 500 statusCode pages has been shifted to the Vuex UI/statusCode because of fetch()
        // Redirect on the Homepage if there is no data coming from the API
        if (!response || response.status !== 200) {
          // IMPORTANT: On Server Side we updating the statusCode if the URL or the REDIRECT have not been found
          // This will fix some SEO problems with SSR
          // https://github.com/nuxt/nuxt.js/issues/7285
          // https://nuxtjs.org/announcements/understanding-how-fetch-works-in-nuxt-2-12/#error-handling
          // https://nuxtjs.org/announcements/understanding-how-fetch-works-in-nuxt-2-12/#5-error-handling
          if (process.server) {
            context.$nuxt.context.res.statusCode = response.status
          }
          context.$store.commit('UI/statusCode', response.status, { root: true })

          return {
            statusCode: response.status
          }
        }
      }
    })
    .catch(e => {
      console.log('ERROR=', e)
      // Display the 500 Satus Code Info and force 500 to the real StatusCode of the page
      if (process.server) {
        context.$nuxt.context.res.statusCode = 500
      }
      context.$store.commit('UI/statusCode', 500, { root: true })

      return {
        statusCode: 500
      }
    })
}

export async function callAWSFrontendExtrasAPI({ context, useCache }) {
  const config = context?.$nuxt?.$config || context?.$config || null
  const store = context?.$store || context?.store || null
  // Calling the Utility Frontend APIS to get the sitewide and sitelinks data
  process.env.NODE_ENV !== 'production' && require('axios-debug-log')

  const url = config.AWSAPIGatewayURL + '/utility/get-page-extras'

  const request = context.$axios.get(url, {
    headers: {
      'Content-Type': 'application/json',
      'x-api-key': config.AWSAPIGatewayXAPIKEY,
      'Cache-Control': useCache === true ? 'max-age=3600' : 'max-age=0'
    },
    data: JSON.stringify({
      useCache
    }),
    timeout: config.AWSAPIGatewayTimeout,
    useCache
  })

  return await request
    .then(response => {
      // handle responses here
      console.info('RES paylaod', response?.data?.payload)

      response?.data?.payload?.sidelinks && store && store.commit('utility/sitelinks', response?.data?.payload?.sidelinks, { root: true })
      response?.data?.payload?.sidelinksmobile && store && store.commit('utility/sitelinksmobile', response?.data?.payload?.sidelinksmobile, { root: true })
      response?.data?.payload?.sitewide && store && store.commit('utility/sitewide', response?.data?.payload?.sitewide, { root: true })
      response?.data?.payload?.sitewidemobile && store && store.commit('utility/sitewidemobile', response?.data?.payload?.sitewidemobile, { root: true })
      response?.data?.payload?.bestsellers && store && store.commit('utility/bestsellers', response?.data?.payload?.bestsellers, { root: true })
      response?.data?.payload?.timestamp && store && store.commit('utility/timestamp', response?.data?.payload?.timestamp, { root: true })

      return {
        sitelinks: response?.data?.payload?.sidelinks || [],
        sitelinksmobile: (response?.data?.payload?.sidelinksmobile && response?.data?.payload?.sidelinksmobile.slice(0, 8)) || [],
        sitewide: response?.data?.payload?.sitewide || {},
        sitewidemobile: response?.data?.payload?.sitewidemobile || {},
        bestsellers: (response?.data?.payload?.bestsellers && response?.data?.payload?.bestsellers.slice(0, 6)) || [],
        timestamp: response?.data?.payload?.timestamp
      }
    })
    .catch(error => {
      // handle errors here
      console.error(error)
      return {}
    })
}
