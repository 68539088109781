import Vue from 'vue'

import { library, config } from '@fortawesome/fontawesome-svg-core'

import { faSearch } from '@fortawesome/pro-light-svg-icons/faSearch'
import { faCircle } from '@fortawesome/pro-light-svg-icons/faCircle'
import { faShoppingBasket } from '@fortawesome/pro-light-svg-icons/faShoppingBasket'
import { faBars } from '@fortawesome/pro-light-svg-icons/faBars'
import { faChevronRight } from '@fortawesome/pro-light-svg-icons/faChevronRight'
import { faChevronLeft } from '@fortawesome/pro-light-svg-icons/faChevronLeft'
import { faChevronDown } from '@fortawesome/pro-light-svg-icons/faChevronDown'
import { faPlus } from '@fortawesome/pro-light-svg-icons/faPlus'
import { faMinus } from '@fortawesome/pro-light-svg-icons/faMinus'
import { faTimes } from '@fortawesome/pro-light-svg-icons/faTimes'
import { faSurprise } from '@fortawesome/pro-light-svg-icons/faSurprise'
import { faTrash } from '@fortawesome/pro-light-svg-icons/faTrash'
import { faFemale } from '@fortawesome/pro-light-svg-icons/faFemale'
import { faHeart } from '@fortawesome/pro-light-svg-icons/faHeart'
import { faShoppingBag } from '@fortawesome/pro-light-svg-icons/faShoppingBag'
import { faUtensils } from '@fortawesome/pro-light-svg-icons/faUtensils'
import { faChild } from '@fortawesome/pro-light-svg-icons/faChild'
import { faLaptop } from '@fortawesome/pro-light-svg-icons/faLaptop'
import { faCamera } from '@fortawesome/pro-light-svg-icons/faCamera'
import { faMale } from '@fortawesome/pro-light-svg-icons/faMale'
import { faPaintBrushAlt } from '@fortawesome/pro-light-svg-icons/faPaintBrushAlt'
import { faCar } from '@fortawesome/pro-light-svg-icons/faCar'
import { faRunning } from '@fortawesome/pro-light-svg-icons/faRunning'
import { faGamepad } from '@fortawesome/pro-light-svg-icons/faGamepad'
import { faFlowerDaffodil } from '@fortawesome/pro-light-svg-icons/faFlowerDaffodil'
import { faGift } from '@fortawesome/pro-light-svg-icons/faGift'
import { faNewspaper } from '@fortawesome/pro-light-svg-icons/faNewspaper'
import { faPaw } from '@fortawesome/pro-light-svg-icons/faPaw'
import { faFile } from '@fortawesome/pro-light-svg-icons/faFile'
import { faPhoneLaptop } from '@fortawesome/pro-light-svg-icons/faPhoneLaptop'
import { faHome } from '@fortawesome/pro-light-svg-icons/faHome'
import { faFilter } from '@fortawesome/pro-light-svg-icons/faFilter'
import { faCheck } from '@fortawesome/pro-light-svg-icons/faCheck'
import { faBolt } from '@fortawesome/pro-solid-svg-icons/faBolt'
import { faCalendarAlt } from '@fortawesome/pro-light-svg-icons/faCalendarAlt'
import { faEnvelope } from '@fortawesome/pro-light-svg-icons/faEnvelope'
import { faGiftCard } from '@fortawesome/pro-light-svg-icons/faGiftCard'
import { faTag } from '@fortawesome/pro-light-svg-icons/faTag'
import { faAward } from '@fortawesome/pro-light-svg-icons/faAward'
import { faChartLine } from '@fortawesome/pro-light-svg-icons/faChartLine'
import { faGifts } from '@fortawesome/pro-light-svg-icons/faGifts'
import { faQuestion } from '@fortawesome/pro-light-svg-icons/faQuestion'
import { faGlobeAmericas } from '@fortawesome/pro-light-svg-icons/faGlobeAmericas'

import { faFacebookSquare } from '@fortawesome/free-brands-svg-icons/faFacebookSquare'
import { faTwitterSquare } from '@fortawesome/free-brands-svg-icons/faTwitterSquare'
import { faInstagram } from '@fortawesome/free-brands-svg-icons/faInstagram'
import { faPinterestSquare } from '@fortawesome/free-brands-svg-icons/faPinterestSquare'
import { faYoutubeSquare } from '@fortawesome/free-brands-svg-icons/faYoutubeSquare'
import { faWhatsappSquare } from '@fortawesome/free-brands-svg-icons/faWhatsappSquare'

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

// This is important, we are going to let Nuxt.js worry about the CSS
config.autoAddCss = false

library.add(
  faSearch,
  faCircle,
  faShoppingBasket,
  faBars,
  faChevronRight,
  faChevronLeft,
  faChevronDown,
  faPlus,
  faMinus,
  faTimes,
  faSurprise,
  faTrash,
  faFemale,
  faHeart,
  faShoppingBag,
  faUtensils,
  faChild,
  faLaptop,
  faCamera,
  faMale,
  faPaintBrushAlt,
  faCar,
  faRunning,
  faGamepad,
  faFlowerDaffodil,
  faGift,
  faNewspaper,
  faPaw,
  faFile,
  faPhoneLaptop,
  faFacebookSquare,
  faTwitterSquare,
  faInstagram,
  faPinterestSquare,
  faYoutubeSquare,
  faWhatsappSquare,
  faHome,
  faFilter,
  faCheck,
  faBolt,
  faCalendarAlt,
  faEnvelope,
  faGiftCard,
  faTag,
  faAward,
  faChartLine,
  faGifts,
  faQuestion,
  faGlobeAmericas
)

Vue.component('FontAwesomeIcon', FontAwesomeIcon)
