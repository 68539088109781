export default () => ({
  // The Page Status Code (200,404,500 for example)
  statusCode: undefined,

  // The Debug flag
  debug: undefined,

  // The use Cache Flag
  useCache: undefined,

  // The Page Breadcrumbs
  breadcrumbs: [],

  // IS MOBILE flag used on a change orientation
  isMobile: false,

  // banners data info
  banners: [],

  // Page expiresAt timestamp
  expiresAt: undefined,

  // FAQs
  faqs: []
})
