export default {
  products(state) {
    return state.products && state.products.length ? state.products : undefined
  },
  categories(state) {
    return state.categories && state.categories.length ? state.categories : undefined
  },
  title(state) {
    return state.title
  },
  query(state) {
    return state.query
  },
  count(state) {
    if (state.activeFilter) {
      return state.count
    }
    return state.count <= 50 ? state.count : 50
  },
  activeFilter(state) {
    return state.activeFilter
  }
}
