import _assign from 'lodash/assign'
import { aggregateTotals } from '~/lib/util/basket'

export default {
  id(state, payload) {
    state.id = payload
  },

  // Injest the Basket payload
  payload(state, payload) {
    _assign(state, payload)
    if (payload) {
      state.discount = (payload.discount && { amount: payload.discount.amount, description: payload.discount.description }) || {}
    }
  },

  // Injest the Basket Upsell payload
  upsell(state, payload) {
    state.upsell = [...payload]
  },

  // Create the new Basket Totals
  aggregateTotals(state) {
    // console.log('aggregateTotals state.basket=', state)
    if (state?.items?.length >= 1) {
      // console.log('aggregateTotals state.basket.items=', state.items)
      // TODO MongoDB INTEGRATION
      const newTotals = aggregateTotals(state)
      // console.log('aggregateTotals newTotals=', newTotals)
      state.totals = { ...newTotals }
    }
  },

  // Reset the Basket for Reactivity
  resetBasket(state) {
    state.numOfItems = undefined
  }
}
