export default () => ({
  // M1 Basket ID
  id: undefined,

  // The Basket Items
  items: {},

  // The num of Basket Items
  numOfItems: 0,

  // Basket Saving in GBP
  save: undefined,

  // Basket Total in GBP
  total: undefined,

  // Basket Discount
  discount: undefined,

  // Aggregated Basket Totals in GBP
  totals: undefined,

  // UpdatedAt time
  updatedAt: undefined,

  // Upsell Page data
  upsell: [],

  // The nuxt-vuex-localstorage version
  version: 6
})
