const NAVIGATION_LINKS_DEFAULT_VALUES = [
  {
    key: 2,
    text: 'Sale Offers',
    alt: 'Sale Offers',
    href: '/discounted-magazine-sale-offers'
  },
  {
    key: 11,
    text: 'Instant Digital Access',
    alt: 'Instant Digital Access',
    href: '/magazines-with-free-digital-access'
  },
  {
    key: 12,
    text: 'Best Selling Magazines',
    alt: 'Best Selling Magazines',
    href: '/best-selling-magazine-subscriptions'
  },
  {
    key: 13,
    text: 'Magazines for Women',
    alt: 'Magazines for Women',
    href: '/magazine-subscriptions-for-women'
  },
  {
    key: 14,
    text: 'Magazines for Men',
    alt: 'Magazines for Men',
    href: '/magazine-subscriptions-for-men'
  },
  {
    key: 15,
    text: 'Magazines for Kids',
    alt: 'Magazines for Kids',
    href: '/kids-magazines'
  },
  {
    key: 16,
    text: 'Digital Only Subscriptions',
    alt: 'Digital Only Subscriptions',
    href: '/digital-magazines'
  },
  {
    key: 17,
    text: 'Gift Subscription Ideas',
    alt: 'Gift Subscription Ideas',
    href: '/magazine-subscription-gift-ideas'
  },
  {
    key: 18,
    text: 'Magazines with Free Gifts',
    alt: 'Magazines with Free Gifts',
    href: '/magazines-with-free-gifts'
  },
  // {
  //   key: 19,
  //   text: 'Single Issues',
  //   alt: 'Single Issues',
  //   href: '/single-issues'
  // },
  {
    key: 20,
    text: 'Gift Cards',
    alt: 'Gift Cards',
    href: '/gift-card-options'
  },
  {
    key: 21,
    text: 'Gift Finder',
    alt: 'Gift Finder',
    href: '/gift_finder'
  }
]

const NAVIGATION_LINKS_MORE_VALUES = [
  {
    key: 100,
    text: 'Hobbies & Leisure',
    href: '/hobbies-and-leisure-magazines'
  },
  {
    key: 101,
    text: 'Sports',
    href: '/sports-magazines'
  },
  {
    key: 102,
    text: 'Fashion & Beauty',
    href: '/fashion-and-beauty-magazines'
  },
  {
    key: 103,
    text: 'Motoring & Transport',
    href: '/motoring-and-transport-magazines'
  },
  {
    key: 104,
    text: 'Entertainment',
    href: '/entertainment-magazines'
  },
  {
    key: 105,
    text: 'Lifestyle',
    href: '/lifestyle-magazines'
  },
  {
    key: 106,
    text: 'Home & Gardening',
    href: '/home-and-gardening-magazines'
  },
  {
    key: 107,
    text: 'Food & Drink',
    href: '/food-and-drink-magazines'
  },
  {
    key: 108,
    text: 'News & Current Affairs',
    href: '/news-and-current-affairs-magazines'
  },
  {
    key: 109,
    text: 'Computers & Technology',
    href: '/computers-and-technology-magazines'
  },
  {
    key: 110,
    text: 'Science & Nature',
    href: '/science-and-nature-magazines'
  },
  {
    key: 111,
    text: 'Arts & Culture',
    href: '/arts-and-culture-magazines'
  },
  {
    key: 112,
    text: 'Kids',
    href: '/kids-magazines'
  },
  {
    key: 113,
    text: 'Business & Finance',
    href: '/business-and-finance-magazines'
  },
  {
    key: 114,
    text: 'Industry & Trade',
    href: '/industry-and-trade-magazines'
  },
  // {
  //   key: 115,
  //   text: 'Single Issues',
  //   href: '/single-issues'
  // },
  {
    key: 116,
    text: 'International',
    href: '/international-offers'
  }
]

const BEST_SELLERS_DEFAULT = [
  {
    title: 'MoneyWeek',
    url: '/magazines/moneyweek-magazine',
    imageUrl: '/catalog/product/m/o/moneyweek_1.jpg?fm=png&auto=format&fit=fill&fill=solid&bg=0FFF&w=180&h=250',
    productId: 2674,
    id: 'MYWK',
    sku: 'Moneyweek',
    offer: {
      type: 'PRINT',
      name: '6 issues for £0.00',
      save: 100,
      productId: 1298,
      id: 'MYWK',
      paymentType: 'DD',
      sku: '2587',
      price: '0.00',
      cpa: '9.50',
      rrp: '27.00',
      numOfIssues: 6
    },
    category: 'Business and Finance',
    subCategory: 'Money and Finance'
  },
  {
    title: 'The Peoples Friend',
    url: '/magazines/the-people-s-friend-magazine',
    imageUrl: '/catalog/product/t/h/the-peoples-friend-magazine_225.jpg?fm=png&auto=format&fit=fill&fill=solid&bg=0FFF&w=180&h=250',
    productId: 2716,
    id: 'TPD1',
    sku: 'The Peoples Friend',
    offer: {
      type: 'PRINT',
      name: '12 issues for £16.00',
      save: 11,
      productId: 13429,
      id: 'TPD1',
      paymentType: 'DD',
      sku: '11456',
      price: '16.00',
      cpa: '11.00',
      rrp: '18.00',
      numOfIssues: 12
    },
    category: 'Entertainment',
    subCategory: 'Literature'
  },
  {
    title: 'Classic and Sports Car',
    url: '/magazines/classic-and-sports-car-magazine',
    imageUrl: '/catalog/product/c/l/classic-and-sports-car-magazine_71.jpg?fm=png&auto=format&fit=fill&fill=solid&bg=0FFF&w=180&h=250',
    productId: 2599,
    id: 'HCSC',
    sku: 'Classic and Sports Car',
    offer: {
      type: 'PRINT',
      name: '3 issues for £3.00',
      save: 83,
      productId: 533,
      id: 'HCSC',
      paymentType: 'DD',
      sku: '1552',
      price: '3.00',
      cpa: '14.50',
      rrp: '17.97',
      numOfIssues: 3
    },
    category: 'Hobbies and Leisure',
    subCategory: 'Cars'
  },
  {
    title: 'Woman & Home',
    url: '/magazines/woman-and-home-magazine',
    imageUrl: '/catalog/product/w/o/woman-and-home-magazine_88.jpg?fm=png&auto=format&fit=fill&fill=solid&bg=0FFF&w=180&h=250',
    productId: 2604,
    id: 'WOI1',
    sku: 'Woman & Home',
    offer: {
      type: 'PRINT',
      name: '6 issues for £19.75',
      save: 34,
      productId: 356,
      id: 'WOI1',
      paymentType: 'DD',
      sku: '3008',
      price: '19.75',
      cpa: '13.50',
      rrp: '29.94',
      numOfIssues: 6
    },
    category: 'Home and Garden',
    subCategory: 'House and Home'
  },
  {
    title: 'Good Housekeeping',
    url: '/magazines/good-housekeeping-magazine',
    imageUrl: '/catalog/product/g/o/good-housekeeping-magazine_10_34.jpg?fm=png&auto=format&fit=fill&fill=solid&bg=0FFF&w=180&h=250',
    productId: 8423,
    id: 'NGHK',
    sku: 'Good Housekeeping',
    offer: {
      type: 'PRINT',
      name: '6 issues for £22.50',
      save: 25,
      productId: 133,
      id: 'NGHK',
      paymentType: 'DD',
      sku: '5163',
      price: '22.50',
      cpa: '11.00',
      rrp: '29.94',
      numOfIssues: 6
    },
    category: 'Home and Garden',
    subCategory: 'House and Home'
  },
  {
    title: 'Psychologies',
    url: '/magazines/psychologies-magazine',
    imageUrl: '/catalog/product/p/s/psychologies-magazine_68.jpg?fm=png&auto=format&fit=fill&fill=solid&bg=0FFF&w=180&h=250',
    productId: 2808,
    id: 'KPSY',
    sku: 'Psychologies',
    offer: {
      type: 'PRINT',
      name: '13 issues for £42.99',
      save: 31,
      productId: 13583,
      id: 'KPSY',
      paymentType: 'DD',
      sku: '11599',
      price: '42.99',
      cpa: '12.50',
      rrp: '62.40',
      numOfIssues: 13
    },
    category: 'Industry and Trade',
    subCategory: 'Health and Fitness'
  },
  {
    title: 'FourFourTwo',
    url: '/magazines/fourfourtwo-magazine',
    imageUrl: '/catalog/product/f/o/fourfourtwo_1_.jpg?fm=png&auto=format&fit=fill&fill=solid&bg=0FFF&w=180&h=250',
    productId: 3299,
    id: 'HFFT',
    sku: 'FourFourTwo',
    offer: {
      type: 'PRINT',
      name: '6 issues for £23.50',
      save: 35,
      productId: 2158,
      id: 'HFFT',
      paymentType: 'DD',
      sku: '4478',
      price: '23.50',
      cpa: '13.50',
      rrp: '35.94',
      numOfIssues: 6
    },
    category: 'Sport',
    subCategory: 'Football'
  },
  {
    title: 'Country Living',
    url: '/magazines/country-living-magazine',
    imageUrl: '/catalog/product/c/o/country-living-magazine_64.jpg?fm=png&auto=format&fit=fill&fill=solid&bg=0FFF&w=180&h=250',
    productId: 2585,
    id: 'HCLV',
    sku: 'Country Living',
    offer: {
      type: 'PRINT',
      name: '6 issues for £22.50',
      save: 25,
      productId: 11582,
      id: 'HCLV',
      paymentType: 'DD',
      sku: '9736',
      price: '22.50',
      cpa: '11.00',
      rrp: '29.94',
      numOfIssues: 6
    },
    category: 'Home and Garden',
    subCategory: 'Country Life'
  },
  {
    title: 'The Week',
    url: '/magazines/the-week-magazine',
    imageUrl: '/catalog/product/t/h/the-week-magazine_274.jpg?fm=png&auto=format&fit=fill&fill=solid&bg=0FFF&w=180&h=250',
    productId: 2704,
    id: 'TWD1',
    sku: 'The Week',
    offer: {
      type: 'PRINT',
      name: '6 issues for £0.00',
      save: 100,
      productId: 325,
      id: 'TWD1',
      paymentType: 'DD',
      sku: '1364',
      price: '0.00',
      cpa: '11.50',
      rrp: '23.94',
      numOfIssues: 6
    },
    category: 'Business and Finance',
    subCategory: 'Money and Finance'
  },
  {
    title: 'Mens Health',
    url: '/magazines/mens-health-magazine',
    imageUrl: '/catalog/product/m/e/mens-health-magazine_6_37.jpg?fm=png&auto=format&fit=fill&fill=solid&bg=0FFF&w=180&h=250',
    productId: 3867,
    id: 'NMEN',
    sku: 'Mens Health',
    offer: {
      type: 'PRINT',
      name: '5 issues for £17.99',
      save: 23,
      productId: 10754,
      id: 'NMEN',
      paymentType: 'DD',
      sku: '8830',
      price: '17.99',
      cpa: '11.00',
      rrp: '23.50',
      numOfIssues: 5
    },
    category: 'Mens Lifestyle',
    subCategory: 'Health and Fitness'
  },
  {
    title: 'Womens Health',
    url: '/magazines/womens-health-magazine',
    imageUrl: '/catalog/product/w/o/womens-health-magazine_47.jpg?fm=png&auto=format&fit=fill&fill=solid&bg=0FFF&w=180&h=250',
    productId: 3109,
    id: 'WHH1',
    sku: 'Womens Health',
    offer: {
      type: 'PRINT',
      name: '5 issues for £19.99',
      save: 15,
      productId: 10756,
      id: 'WHH1',
      paymentType: 'DD',
      sku: '8832',
      price: '19.99',
      cpa: '11.00',
      rrp: '23.50',
      numOfIssues: 5
    },
    category: 'Womens Lifestyle',
    subCategory: 'Health and Fitness'
  },
  {
    title: 'GQ',
    url: '/magazines/gq-magazine',
    imageUrl: '/catalog/product/g/q/gq_4__1.jpg?fm=png&auto=format&fit=fill&fill=solid&bg=0FFF&w=180&h=250',
    productId: 2952,
    id: 'GQC1',
    sku: 'GQ',
    offer: {
      type: 'PRINT',
      name: '6 issues for £9.00',
      save: 62,
      productId: 12358,
      id: 'GQC1',
      paymentType: 'DD',
      sku: '10475',
      price: '9.00',
      cpa: '9.00',
      rrp: '23.94',
      numOfIssues: 6
    },
    category: 'Mens Lifestyle',
    subCategory: 'Technology'
  }
]

function getNavigationLinks({ withMoreLinks, defaultLinks = [] }) {
  return withMoreLinks ? defaultLinks.concat(NAVIGATION_LINKS_MORE_VALUES) : defaultLinks
}

export { getNavigationLinks, NAVIGATION_LINKS_DEFAULT_VALUES, NAVIGATION_LINKS_MORE_VALUES, BEST_SELLERS_DEFAULT }
