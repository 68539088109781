import { cacheAdapterEnhancer, throttleAdapterEnhancer } from 'axios-extensions'
const axios = require('axios')

export function azureSearch({ searchTerm, orderby, top, isFuzzy }, cb) {
  let productSearchTerm = searchTerm.slice(-1) === 's' ? searchTerm.slice(0, -1) : searchTerm
  let productSuggestionTerm = searchTerm
  let categorySuggestionTerm = searchTerm

  if (/([.*+?^=!:${}()|])/.test(searchTerm)) {
    // Some of the title like OK! Magazine and delicious. has special chars, we have to escape them
    productSearchTerm = searchTerm.replace(/([.*+?^=!:${}()|])/g, '\\$1')
    productSuggestionTerm = searchTerm.replace(/([.*+?^=!:${}()|])/g, '\\$1')
    categorySuggestionTerm = searchTerm.replace(/([.*+?^=!:${}()|])/g, '\\$1')
  }

  productSearchTerm = productSearchTerm.replace(/ /g, ' + ') + '*'
  productSuggestionTerm = productSuggestionTerm.replace(/ /g, ' + ') + '*'

  console.log('Product  SEARCH     Term=' + productSearchTerm)
  console.log('Product  SUGGESTION Term=' + productSuggestionTerm)
  console.log('Category SUGGESTION Term=' + categorySuggestionTerm)

  const params = {
    'api-version': `${process.env.AZURE_SEARCH_API_VERSION}`,
    searchMode: 'all',
    queryType: 'full',
    $count: true,
    search: productSearchTerm,
    searchFields: 'term,title,desc',
    $orderby: orderby,
    $filter: `(type ne 'category' and type ne 'pocketmagsplus')`,
    $top: top || process.env.AZURE_SEARCH_PAGESIZE,
    $select: 'type,title,id,url,redirect,term,image,desc,offerProductType,offerName,offerSave,offerProductId,offerPaymentType,offerSku,offerPrice,offerCpa,isDelete'
    // highlight: 'title-5,term-5',
    // highlightPreTag: '<em>',
    // highlightPostTag: '</em>',
  }
  // console.log('AZURE PARAMS=', params)
  return axios
    .all([
      axios.get(`${process.env.AZURE_SEARCH_API_ENDPOINT}`, {
        headers: {
          'Content-Type': 'application/json',
          'api-key': `${process.env.AZURE_SEARCH_API_KEY}`
        },
        params
      }),
      productSuggestionTerm.length >= 4 &&
        axios.get(`${process.env.AZURE_SEARCH_API_ENDPOINT}/suggest`, {
          headers: {
            'Content-Type': 'application/json',
            'api-key': `${process.env.AZURE_SEARCH_API_KEY}`
          },
          params: {
            'api-version': `${process.env.AZURE_SEARCH_API_VERSION}`,
            search: productSuggestionTerm.length >= 3 ? productSuggestionTerm : '',
            $count: true,
            $top: top || process.env.AZURE_SEARCH_PAGESIZE,
            fuzzy: true,
            suggesterName: 'sg',
            highlightPreTag: '<b>',
            highlightPostTag: '</b>',
            $filter: `(type ne 'category')`,
            $select: 'type,title,id,url,redirect,term,image,desc,offerProductType,offerName,offerSave,offerProductId,offerPaymentType,offerSku,offerPrice,offerCpa,isDelete',
            $orderby: orderby,
            searchFields: 'term, title, desc',
            minimumCoverage: 100
          }
        }),
      categorySuggestionTerm.length >= 3 &&
        axios.get(`${process.env.AZURE_SEARCH_API_ENDPOINT}/suggest`, {
          headers: {
            'Content-Type': 'application/json',
            'api-key': `${process.env.AZURE_SEARCH_API_KEY}`
          },
          params: {
            'api-version': `${process.env.AZURE_SEARCH_API_VERSION}`,
            search: categorySuggestionTerm,
            $count: true,
            $top: top || process.env.AZURE_SEARCH_PAGESIZE,
            fuzzy: false,
            suggesterName: 'sg',
            highlightPreTag: '<b>',
            highlightPostTag: '</b>',
            $filter: `(type eq 'category')`,
            $select: 'id,title,titleToLower,path,category_id,category,category1,category2,url,isDelete,level,type',
            $orderby: 'level asc'
          }
        })
    ])
    .then(responseArr => {
      // this will be executed only when all requests are complete
      let products = []
      let categories = []

      if (responseArr[0].data['@odata.count'] >= 1) {
        // Product SEARCH
        // console.log(responseArr[0].data)
        console.log('PRODUCT SEARCH #', responseArr[0].data['@odata.count'])
        products = responseArr[0].data
      }

      if (responseArr[1] && responseArr[1].data.value.length > 0 && responseArr[0].data['@odata.count'] <= 1) {
        // Product SUGGESTION
        console.log('PRODUCT SUGGESTION #', responseArr[1].data.value.length)
        products = responseArr[1].data
      }

      if (responseArr[2] && responseArr[2].data.value.length > 0) {
        // Categories SUGGESTION
        console.log('CATEGORIES SUGGESTION #', responseArr[1].data.value.length)
        categories = responseArr[2].data
      }

      cb(null, {
        categories,
        products
      })
    })
    .catch(function(error) {
      cb(error, null)
    })
}

export function azureViewAllSearch(searchTerm, orderby, cb) {
  let azureSearchTerm = searchTerm.replace(/ /g, '* ') + '*'

  // Use Wildcards search
  azureSearchTerm = `${searchTerm}`
  let orderbyMod = orderby.replace(/title/i, 'titleToLower')
  // WA using the price double instead the offerPrice
  orderbyMod = orderby.replace(/offerPrice/i, 'price')
  console.log(`View All Search Term=${azureSearchTerm} orderby=${orderbyMod}`)

  // Using GET for Azure Search
  return axios
    .get(`${process.env.AZURE_SEARCH_API_ENDPOINT}`, {
      headers: {
        'Content-Type': 'application/json',
        'api-key': `${process.env.AZURE_SEARCH_API_KEY}`
      },
      params: {
        'api-version': `${process.env.AZURE_SEARCH_API_VERSION}`,
        searchMode: 'all',
        queryType: 'simple',
        $count: true,
        search: azureSearchTerm,
        searchFields: 'firstDigit',
        $orderby: orderbyMod || 'titleToLower asc',
        $top: 500
      }
    })
    .then(function(response) {
      cb(null, response.data)
    })
    .catch(function(error) {
      cb(error)
    })
}

export function azureGiftFinderSearch({ products }, cb) {
  // Compose the search term with double quotes and pipeline as follow:
  // '"HHAR" | "HCOS" | "HELE" | "NGHK" | "GRB1" | "MCI1" | "HPRI" | "HRED" | "TAC1" | "VFC1" | "VOC1" | "WOI1"'
  // const search = products.map(i => `"${i.fourDigitCode}"`).join(' | ')

  // Compose the search term with pipeline as follow:
  // 'HHAR | HCOS | HELE | NGHK | GRB1 | MCI1 | HPRI | HRED | TAC1 | VFC1 | VOC1 | WOI1'
  const search = products.map(i => `${i.fourDigitCode}`).join(' | ')
  console.log(`azureGiftFinderSearch products #${products.length}`)
  console.log('search=', search)
  const params = {
    'api-version': `${process.env.AZURE_CATEGORYPRODUCTS_API_VERSION}`,
    queryType: 'simple',
    $count: true,
    search,
    searchFields: 'fourDigitCode',
    $orderby: 'fourDigitCode asc',
    $filter: `category_id eq '208'`,
    $select: '*',
    $top: 500
  }
  // console.log('AZURE PARAMS=', params)
  // Using GET for Azure Search
  return axios
    .get(`${process.env.AZURE_CATEGORYPRODUCTS_API_ENDPOINT}`, {
      headers: {
        'Content-Type': 'application/json',
        'api-key': `${process.env.AZURE_CATEGORYPRODUCTS_API_KEY}`
      },
      params
    })
    .then(function(response) {
      // console.log('azureGiftFinderSearch response.data=', response.data)
      cb(null, response.data)
    })
    .catch(function(error) {
      cb(error)
    })
}

export function azureCategoryProductsSearch({ searchTerm, orderby, page, filter, offerPriceInterval, offerSaveInterval, viewAll, isPocketmagsplus, noFacets, top }, cb) {
  // Use Category Products  search
  const azureSearchTerm = searchTerm.trim()
  console.log(`term=${azureSearchTerm}`)
  console.log(`orderby=${orderby}`)
  console.log(`page=${page}`)
  console.log(`filter=${filter}`)
  console.log(`viewAll=${viewAll}`)
  console.log(`isPocketmagsplus=${isPocketmagsplus}`)
  console.log(`offerPriceInterval=${offerPriceInterval}`)

  // Pocketmags Plus+ first page results Logic, we need to retrieve only AZURE_CATEGORYPRODUCTS_PAGESIZE -1 prods
  let pagesize = top || process.env.AZURE_CATEGORYPRODUCTS_PAGESIZE
  let skip = (page - 1) * pagesize || 0
  if (isPocketmagsplus) {
    pagesize = parseInt(page) === 1 ? pagesize - 1 : pagesize
    skip = parseInt(page) === 1 ? skip : skip - 1
  }

  const params = {
    'api-version': `${process.env.AZURE_CATEGORYPRODUCTS_API_VERSION}`,
    searchMode: 'any',
    queryType: 'full',
    $count: true,
    search: azureSearchTerm,
    searchFields: 'category_id',
    $orderby: orderby ? `${orderby}` : 'positionInt asc',
    $filter: filter,
    $top: pagesize,
    $skip: skip
    // facet: [`offerPrice,interval:${offerPriceInterval || 15}`, 'offerProductType,sort:value', `offerSave,interval:${offerSaveInterval || 20}`, 'hasFreeGift', 'delivery']
  }

  let facets = [`offerPrice,interval:${offerPriceInterval || 15}`, 'offerProductType,sort:value', `offerSave,interval:${offerSaveInterval || 20}`, 'hasFreeGift', 'delivery']
  facets = 'facet=' + facets.join('&facet=')

  console.log(`azureCategoryProductsSearch FACETS=${facets}`)

  // Do not use facets
  if (noFacets === true) {
    params.noFacets = undefined
  }

  if (viewAll === true) {
    params.$top = '1000'
    params.$skip = 0
  }

  console.log(params)

  const http = axios.create({
    headers: {
      'Content-Type': 'application/json',
      'api-key': `${process.env.AZURE_CATEGORYPRODUCTS_API_KEY}`
    },
    adapter: throttleAdapterEnhancer(cacheAdapterEnhancer(axios.defaults.adapter))
  })

  return http
    .get(`${process.env.AZURE_CATEGORYPRODUCTS_API_ENDPOINT}?${facets}`, {
      params,
      cache: process.env.AZURE_USE_CACHE === 'true'
    })
    .then(function(response) {
      console.log(response.data['@search.facets'].offerPrice)
      cb(null, response.data)
    })
    .catch(function(error) {
      cb(error)
    })
}

export function filterAzuresearchResultsToProduct(azureResult) {
  if (azureResult?.id?.startsWith('q_')) {
    return false
  }
  return true
}
export function mapAzuresearchResultsToProduct(azureResult) {
  return {
    title: azureResult.sku || azureResult.title,
    titleToLower: azureResult.titleToLower || '',
    url: `/${azureResult.url}`,
    imageUrl: `${azureResult.image}`,
    productId: azureResult.id.replace('p_', ''),
    hasFreeGift: azureResult.hasFreeGift,
    sku: azureResult.sku,
    typename: azureResult.typename,
    publisher: {
      code: azureResult?.publisherCode || undefined,
      email: azureResult?.publisherEmail || undefined,
      legalName: azureResult?.publisherLegalName || undefined,
      name: azureResult?.publisherName || undefined,
      phone: azureResult?.publisherPhone || undefined
    },
    offer: {
      type: azureResult.offerPaymentType,
      name: azureResult.offerName,
      save: azureResult.offerSave,
      productId: azureResult.offerProductId,
      paymentType: azureResult.offerPaymentType,
      sku: azureResult.offerSku,
      price: azureResult.offerPrice,
      cpa: azureResult.offerCpa,
      rrp: azureResult.offerRrp,
      numOfIssues: azureResult.offerNumOfIssues
    },
    breadcrumbs: {
      first: false,
      value: azureResult.breadcrumbCatName,
      url: azureResult.breadcrumbCatUrl,
      last: false
    },
    delivery: azureResult.delivery,
    category: azureResult.category,
    subCategory: azureResult.subCategory
  }
}

export function getOrderbyAzureSearchOption({ query, useFirstDigit, isSearchResult }) {
  // create the orderby search option
  const allowedValues = ['asc', 'desc']
  const orderbyPriceValue = query.price || undefined
  const isOrderbyPrice = !!allowedValues.includes(orderbyPriceValue)

  const orderbyNameValue = query.name || undefined
  const isOrderbyName = !!allowedValues.includes(orderbyNameValue)

  let orderby = ''
  if (useFirstDigit) {
    if (isOrderbyPrice) {
      orderby = `offerPrice ${orderbyPriceValue}`
    } else if (isOrderbyName) {
      orderby = `title ${orderbyNameValue}`
    } else {
      orderby = `title asc`
    }
  } else if (isSearchResult) {
    if (isOrderbyPrice) {
      orderby = `offerPrice ${orderbyPriceValue}`
    } else if (isOrderbyName) {
      orderby = `title ${orderbyNameValue}`
    } else {
      orderby = undefined
    }
  } else if (isOrderbyPrice) {
    orderby = `offerPrice ${orderbyPriceValue}`
  } else if (isOrderbyName) {
    orderby = `title ${orderbyNameValue}`
  } else {
    orderby = `positionInt asc`
  }

  return orderby
}

export function createAzureSearchQuery({ query, category, filter }) {
  // create the search query
  const search = query.q || query.t

  const allowedValues = ['asc', 'desc']
  const orderbyPriceValue = query.price || undefined
  const isOrderbyPrice = !!allowedValues.includes(orderbyPriceValue)

  const orderbyNameValue = query.name || undefined
  const isOrderbyName = !!allowedValues.includes(orderbyNameValue)

  const page = query.page || '1'
  const categoryId = category ? category.id : undefined

  let offerPrice
  let offerSave
  let hasFreeGift
  let offerProductType
  let delivery
  if (filter) {
    if (filter.offerPrice) {
      offerPrice = `${filter.offerPrice[0]}~${filter.offerPrice[1]}`
    }
    if (filter.offerSave) {
      offerSave = `0~${filter.offerSave}`
    }
    if (filter.hasFreeGift) {
      hasFreeGift = filter.hasFreeGift
    }
    if (filter.offerProductType) {
      offerProductType = filter.offerProductType
    }
    if (filter.delivery) {
      delivery = filter.delivery
    }
  } else if (query) {
    if (query.op !== undefined) {
      offerPrice = query.op
    }
    if (query.os !== undefined) {
      offerSave = query.os
    }
    if (query.ofg !== undefined) {
      hasFreeGift = query.ofg
    }
    if (query.opt !== undefined) {
      offerProductType = query.opt
    }
    if (query.delivery !== undefined) {
      delivery = query.delivery
    }
  }

  return {
    q: search,
    t: search,
    price: isOrderbyPrice && orderbyPriceValue,
    name: !isOrderbyPrice && isOrderbyName ? orderbyNameValue : false,
    page,
    categoryId,
    op: offerPrice,
    os: offerSave,
    ofg: hasFreeGift,
    opt: offerProductType,
    delivery,
    limit: query.limit === 'all' ? query.limit : undefined
  }
}

export function getCategoryProductQuery(query) {
  const template = `(category_id eq ${query.categoryId})`
  return template
}

export function getCategoryProductFilter({ query, categoryFilter }) {
  const template = []

  // Default category fiter by Magento
  if (categoryFilter) {
    if (categoryFilter.priceTo !== 0) {
      template.push(`(offerPrice ge ${categoryFilter.priceFrom} and offerPrice le ${parseInt(categoryFilter.priceTo) + 0.99})`)
    }
    if (categoryFilter.percentageTo !== 0) {
      template.push(`(offerSave ge ${categoryFilter.percentageFrom} and offerSave le ${categoryFilter.percentageTo})`)
    }
  }

  // offerPrice -> op
  if (query && query.op !== undefined) {
    const range = query.op.split('~')
    template.push(`(offerPrice ge ${range[0]} and offerPrice le ${parseInt(range[1]) + 0.99})`)
  }

  // offerSave -> os
  if (query && query.os !== undefined) {
    if (query.os === 0) {
      template.push(`(offerSave ge 0)`)
    } else {
      template.push(`(offerSave ge ${query.os})`)
    }
  }
  // hasFreeGift -> ofg
  if (query && query.ofg !== undefined) {
    template.push(`(hasFreeGift eq ${query.ofg})`)
  }

  // offerProductType -> opt
  if (query && query.opt !== undefined) {
    template.push(`(offerProductType eq '${query.opt}')`)
  }

  // delivery -> deliveryß
  if (query && query.delivery !== undefined) {
    template.push(`(delivery eq '${query.delivery}')`)
  }
  return template.length === 0 ? template : template.join(' and ')
}

export function createAzureSearchIntervals(categoryFilter) {
  console.log('createAzureSearchIntervals categoryFilter=', categoryFilter)
  if (categoryFilter) {
    let interval = {}
    const priceFrom = categoryFilter?.priceFrom || 0
    const priceTo = categoryFilter?.priceTo || 0
    // console.log('createAzureSearchIntervals priceFrom=', priceFrom)
    // console.log('createAzureSearchIntervals priceTo=', priceTo)

    if (categoryFilter?.urlPath?.includes('single-issues')) {
      // Single Issues Cats
      interval = { offerPriceInterval: 1 }
    } else if (priceFrom === priceTo && priceTo === 0) {
      // Deafult Cats
      interval = undefined
    } else if (priceTo) {
      // Big range Cats
      interval = { offerPriceInterval: Math.ceil((priceTo - priceFrom) / 10) > 20 ? 20 : Math.ceil((priceTo - priceFrom) / 20) }
    }

    if (categoryFilter.percentageTo !== 0) {
      // Cats with from and to precentage
      interval = { offerSaveInterval: 25 }
    }
    console.log('createAzureSearchIntervals interval=', interval)
    return interval
  }
}
