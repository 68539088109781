export default () => ({
  products: [],
  categories: [],
  title: '',
  query: {
    q: '*', // query
    price: null, // sort
    name: null, // name
    limit: null // View all flag if 'all'
  },
  count: 0,
  activeFilter: undefined
})
