const jwt = require('jwt-simple')

export default function({ app, store, route }) {
  if (route.path === '/health') {
    console.log(`JMAG /health`)
    return
  }

  // Cart detection
  try {
    // M1 basket id from cookies
    let basket
    if (process.server) {
      basket = jwt.decode(app.$cookies.get('cart'), process.env.JWT_SECRET)
    } else {
      basket = jwt.decode(this.$cookies.get('cart'), process.env.JWT_SECRET)
    }
    console.log(`COOKIES basket.cartId=${basket.cartId}`)
    basket && basket.cartId && store.commit('basket/id', basket.cartId)

    // M1 frontend id from cookies
    let OMFrontend
    if (process.server) {
      OMFrontend = app.$cookies.get('om_frontend') || undefined
    } else {
      OMFrontend = this.$cookies.get('om_frontend') || undefined
    }
    console.log(`COOKIES om_frontend=${OMFrontend}`)
    OMFrontend && store.commit('s/OMFrontend', OMFrontend)
  } catch (err) {
    console.log('basket.cartId JWT.decode ERROR')
  }
}
