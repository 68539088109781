export default {
  // Get the Page Status Code
  statusCode(state) {
    return state.statusCode
  },

  // Get the Debug flag
  debug(state) {
    return state.debug
  },

  // Get the use Cache Flag
  useCache(state) {
    return state.useCache
  },

  // Get the Page Breadcrumbs
  breadcrumbs(state) {
    return state.breadcrumbs
  },

  // Get the IS MOBILE flag
  isMobile(state) {
    return state.isMobile
  },

  // Get the benners info
  banners(state) {
    return state.banners
  },

  // Get the expiresAt timestamp
  expiresAt(state) {
    return state.expiresAt
  },

  // Get the faqs timestamp
  faqs(state) {
    return state.faqs
  }
}
