import reduce from 'lodash/reduce'

export function aggregateTotals(basket) {
  // console.log('aggregateTotals basket=', basket)
  // Calc the totals of the basket
  if (basket.items) {
    const totals = reduce(
      basket.items,
      (result, item) => ({
        numOfItems: parseInt(result.numOfItems) + parseInt(item.offer.qty),
        save: result.save + Number(item.offer.save).toFixed(2) * item.offer.qty,
        total: result.total + Number(item.offer.price).toFixed(2) * item.offer.qty,
        // discount: (result.discount && { amount: result.discount.amount, description: result.discount.description }) || {},
        subTotal: result.subTotal + Number(item.offer.price).toFixed(2) * item.offer.qty,
        grandTotal: result.grandTotal + Number(item.offer.price).toFixed(2) * item.offer.qty,
        creditCard: {
          numOfItems: item.offer.paymentType === 'CC' ? parseInt(result.creditCard.numOfItems) + parseInt(item.offer.qty) : result.creditCard.numOfItems,
          save: item.offer.paymentType === 'CC' ? result.creditCard.save + Number(item.offer.save).toFixed(2) * item.offer.qty : result.creditCard.save,
          total: item.offer.paymentType === 'CC' ? result.creditCard.total + Number(item.offer.price).toFixed(2) * item.offer.qty : result.creditCard.total,
          // discount: 0, // TODO create the discount
          subTotal: item.offer.paymentType === 'CC' ? result.creditCard.subTotal + Number(item.offer.price).toFixed(2) * item.offer.qty : result.creditCard.subTotal,
          grandTotal: item.offer.paymentType === 'CC' ? result.creditCard.grandTotal + Number(item.offer.price).toFixed(2) * item.offer.qty : result.creditCard.grandTotal
        },
        directDebit: {
          numOfItems: item.offer.paymentType === 'DD' ? parseInt(result.directDebit.numOfItems) + parseInt(item.offer.qty) : result.directDebit.numOfItems,
          save: item.offer.paymentType === 'DD' ? result.directDebit.save + Number(item.offer.save).toFixed(2) * item.offer.qty : result.directDebit.save,
          total: item.offer.paymentType === 'DD' ? result.directDebit.total + Number(item.offer.price).toFixed(2) * item.offer.qty : result.directDebit.total,
          // discount: 0, // TODO create the discount
          subTotal: item.offer.paymentType === 'DD' ? result.directDebit.subTotal + Number(item.offer.price).toFixed(2) * item.offer.qty : result.directDebit.subTotal,
          grandTotal: item.offer.paymentType === 'DD' ? result.directDebit.grandTotal + Number(item.offer.price).toFixed(2) * item.offer.qty : result.directDebit.grandTotal
        }
      }),
      {
        numOfItems: 0,
        save: 0,
        total: 0,
        discount: basket.discount || {},
        subTotal: 0,
        grandTotal: 0,
        creditCard: {
          numOfItems: 0,
          save: 0,
          total: 0,
          discount: 0,
          subTotal: 0,
          grandTotal: 0
        },
        directDebit: {
          numOfItems: 0,
          save: 0,
          total: 0,
          discount: 0,
          subTotal: 0,
          grandTotal: 0
        }
      }
    )

    totals.total -= basket.discount.amount || 0
    console.log('aggregateTotals totals=', totals)
    return totals
  }

  // Default empty basket with cartId if there are no items in the basket
  return {
    items: {},
    numOfItems: 0,
    save: 0,
    total: 0,
    discount: 0,
    subTotal: 0,
    grandTotal: 0,
    creditCard: {
      numOfItems: 0,
      save: 0,
      total: 0,
      discount: 0,
      subTotal: 0,
      grandTotal: 0
    },
    directDebit: {
      numOfItems: 0,
      save: 0,
      total: 0,
      discount: 0,
      subTotal: 0,
      grandTotal: 0
    }
  }
}

export function isMaxQty(item) {
  // Intermedia SINGLE_ISSUES max qty usually 2
  if (item.offer.type === 'SINGLE_ISSUES') {
    return item.offer.qty >= 2
  }

  // Default Max item passed in the ENV, 10 usually LIVE
  return item.offer.qty >= process.env.ITEM_MAX_QTY
}

export function formatCurrency(price) {
  return '£' + Number(price).toFixed(2)
}
