import _find from 'lodash/find'
import { mapAzuresearchResultsToProduct } from '~/lib/util/azuresearch'

export default {
  payload(state, { categoryId, priceFrom, priceTo, percentageFrom, percentageTo }) {
    state.categoryId = categoryId
    state.priceFrom = priceFrom
    state.priceTo = priceTo
    state.percentageFrom = percentageFrom
    state.percentageTo = percentageTo
    state.redirect = undefined
  },

  setCategoryId(state, value) {
    state.categoryId = value
  },

  setProducts(state, products) {
    const newProducts = products.map(product => {
      return mapAzuresearchResultsToProduct(product)
    })

    state.products = newProducts
  },

  setQuery(state, payload) {
    state.query = { ...payload }
  },
  addQueryFilter(state, { type, value }) {
    const found = Object.prototype.hasOwnProperty.call(state, type)

    if (found === false) {
      console.log('addQueryFilter NEW Facets')
      state.query[type] = value
    } else {
      console.log('addQueryFilter Update Facets')
      state.query[type] = value
    }
  },
  removeQueryFilter(state, { type, value }) {
    if (state.query[type]) {
      console.log('removeQueryFilter Remove Facets')
      state.query[type] = undefined
    } else {
      console.log('removeQueryFilter Nothing to do')
    }
  },
  setCount(state, value) {
    state.count = value
  },
  setPage(state, value) {
    return (state.query.page = value)
  },
  setFacets(state, payload) {
    state.facets = { ...payload }
  },
  setActiveFacets(state, payload) {
    state.activeFacets = [...payload]
  },
  addToActiveFacets(state, facetToAdd) {
    const found = _find(state.activeFacets, function(facet) {
      return facet.type === facetToAdd.type
    })
    if (found === undefined) {
      console.log('addToActiveFacets NEW Facets')
      state.activeFacets.push(facetToAdd)
    } else {
      console.log('addToActiveFacets Update Facets')
      found.name = facetToAdd.name
      found.value = facetToAdd.value
    }
  },
  removeFromActiveFacets(state, { facetToRemove, index }) {
    if (index) {
      state.activeFacets && state.activeFacets.splice(index, 1)
    } else {
      state.activeFacets &&
        state.activeFacets.splice(
          state.activeFacets.findIndex(facet => facet.type === facetToRemove.type),
          1
        )
    }
  },
  resetActiveFacets(state) {
    const found = _find(state.activeFacets, function(facet) {
      return facet.type === 'category'
    })
    state.activeFacets = [found]
  }
}
