export default {
  products(state) {
    return state.products && state.products.length ? state.products : undefined
  },
  productsForFB(state) {
    return state.products && state.products.length ? state.products.map(prod => prod.offer.sku + '').slice(0, 20) : []
  },
  categoryId(state) {
    return state.categoryId
  },
  query(state) {
    return state.query
  },
  count(state) {
    return state.count
  },
  page(state) {
    return state.query.page
  },
  facets(state) {
    return state.facets
  },
  activeFacets(state) {
    return state.activeFacets
  },
  priceFrom(state) {
    return state.priceFrom
  },
  priceTo(state) {
    return state.priceTo
  },
  percentageFrom(state) {
    return state.percentageFrom
  },
  percentageTo(state) {
    return state.percentageTo
  },
  redirect(state) {
    return state.redirect
  },
  settings(state) {
    return state
  }
}
