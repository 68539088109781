import _find from 'lodash/find'

// Used to store session values on a Session Storage
export const state = () => ({
  // The custmer Email typed on any Sign Up to Newsletter form
  email: undefined,

  // The om_frontend Magento Cookies
  OMFrontend: undefined,

  // The Affiliate(CJ) ID
  affiliate: undefined,

  // The Recently Viewed Magazines
  recentlyviewed: [],

  // The nuxt-vuex-localstorage version
  version: 6
})

export const mutations = {
  email: (state, value) => {
    state.email = value
    return state
  },

  affiliate(state, payload) {
    state.affiliate = payload
  },

  OMFrontend(state, payload) {
    state.OMFrontend = payload
  },

  addtorecentlyviewed(state, item) {
    console.log(`raddtorecentlyviewed sku=${item.sku}`)
    console.log(item)

    // Return only the last 4 element
    if (!_find(state.recentlyviewed, { sku: item.sku })) {
      console.log('addProduct NOT FOUND')
      const newRecentlyViewed = state.recentlyviewed
      newRecentlyViewed.push(item)
      state.recentlyviewed = newRecentlyViewed.slice(-5)
    } else {
      console.log('addProduct FOUND')
      const newRecentlyViewed = state.recentlyviewed.slice(-5)
      state.recentlyviewed = newRecentlyViewed
    }
  }
}

export const getters = {
  email: state => {
    return state.email
  },

  recentlyviewed(state) {
    if (state.recentlyviewed.length === 0) {
      return []
    }
    if (state.recentlyviewed.length <= 4) {
      return state.recentlyviewed.slice(0, state.recentlyviewed.length - 1)
    }
    return state.recentlyviewed.slice(0, 4)
  }
}
