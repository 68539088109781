const qs = require('qs')

export default $axios => (resource, host = '', customAction = {}, timeout) => ({
  index(payload) {
    if (timeout) {
      $axios.defaults.timeout = timeout
    }
    const action = customAction.index !== undefined ? customAction.index : ''
    return $axios.$get(`${host}/${resource}${action}`, { params: payload })
  },

  create(payload) {
    if (timeout) {
      $axios.defaults.timeout = timeout
    }
    $axios.setHeader('Content-Type', 'application/x-www-form-urlencoded', ['post'])
    const action = customAction.create !== undefined ? customAction.create : ''
    return $axios.$post(`${host}/${resource}${action}`, qs.stringify(payload && ''))
  },

  show(id) {
    if (timeout) {
      $axios.defaults.timeout = timeout
    }
    const action = customAction.show !== undefined ? customAction.show : ''
    return $axios.$get(`${host}/${resource}${action}/${id}`)
  },

  update(payload) {
    if (timeout) {
      $axios.defaults.timeout = timeout
    }
    const action = customAction.update !== undefined ? customAction.update : ''
    return $axios.$put(`${host}/${resource}${action}`, payload)
  },

  delete(id) {
    if (timeout) {
      $axios.defaults.timeout = timeout
    }
    $axios.setHeader('Content-Type', 'application/x-www-form-urlencoded', ['post'])
    const action = customAction.delete !== undefined ? customAction.delete : ''
    return $axios.$post(`${host}/${resource}${action}/${id}`)
  },

  get(params) {
    if (timeout) {
      $axios.defaults.timeout = timeout
    }
    const action = customAction.create !== undefined ? customAction.create : ''
    return $axios.$get(`${host}/${resource}${action}`, { params })
  },

  newsletterCreate(payload) {
    if (timeout) {
      $axios.defaults.timeout = timeout
    }
    console.log(payload)
    $axios.setHeader('Content-Type', 'application/x-www-form-urlencoded', ['post'])
    const action = customAction.create !== undefined ? customAction.create : ''
    return $axios.$post(`${host}/${resource}${action}`, payload)
  }

  // put(payload) {
  //   const action = customAction.update !== undefined ? customAction.update : ''
  //   return $axios.$put(`${host}/${resource}${action}`, payload)
  // }
})
