export default () => ({
  categoryId: undefined,
  products: [],
  query: {
    q: '*', // query
    price: null, // sort
    name: null, // name
    page: 1 // page
  },
  count: 0,
  priceFrom: undefined,
  priceTo: undefined,
  percentageFrom: undefined,
  percentageTo: undefined,
  facets: undefined,
  activeFacets: [],
  redirect: undefined
})
