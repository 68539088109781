import Vue from 'vue'
import StarRating from 'vue-star-rating'
import Paginate from 'vuejs-paginate'
import VueSlider from 'vue-slider-component'
import 'vue-slider-component/theme/antd.css'
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'
import TreeView from 'vue-json-tree-view'
import Datepicker from 'vuejs-datepicker'
import InfiniteLoading from 'vue-infinite-loading'

// Doc https://github.com/craigh411/vue-star-rating
Vue.component('StarRating', StarRating)

// Doc https://github.com/lokyoung/vuejs-paginate
Vue.component('Paginate', Paginate)

// Doc https://www.npmjs.com/package/vue-slider-component
Vue.component('VueSlider', VueSlider)

// Doc https://www.npmjs.com/package/vue-loading-overlay
Vue.component('Loading', Loading)

// Doc https://www.npmjs.com/package/vue-json-tree-view
process.env.NODE_ENV !== 'production' && Vue.use(TreeView)

// Doc https://www.npmjs.com/package/vuejs-datepicker
Vue.component('date-picker', Datepicker)

// Doc https://peachscript.github.io/vue-infinite-loading/guide/#import
Vue.use(InfiniteLoading, {
  // Doc https://peachscript.github.io/vue-infinite-loading/api/#identifier
  slots: {
    noResults: '',
    noMore: '',
    spinner: 'waveDots'
  }
})
