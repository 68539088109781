export default {
  // Set the Page Status Code
  statusCode(state, value) {
    state.statusCode = value
  },

  // Set the Debug flag
  debug(state, value) {
    state.debug = value
  },

  // Set the use Cache Flag
  useCache(state, value) {
    state.useCache = value
  },

  // Set the Page Breadcrumbs
  breadcrumbs(state, value) {
    state.breadcrumbs = value
  },

  // Set the Page IS MOBILE flag
  isMobile(state, value) {
    state.isMobile = value
  },

  // Set the Banner info
  banners(state, values) {
    state.banners = [...values]
  },

  // Set the expiresAt timestamp
  expiresAt(state, value) {
    state.expiresAt = value
  },

  // Set the faqs timestamp
  faqs(state, values) {
    state.faqs = [...values]
  }
}
